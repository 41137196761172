<template>
  <span>
    <b-row>
      <b-col>
        <h1>Manage</h1>
      </b-col>
      <b-col class="text-right">
        <b-button
          style="margin-bottom: 7px"
          @click="$router.push('/pages/dev-tools/checklists')"
        >
          Back
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div id="surveyCreator" style="height: 100vh" />
      </b-col>
    </b-row>
  </span>
</template>

<script>
import checklistApi from '@api/checklist_template_headers';
import { SurveyCreator } from 'survey-creator-knockout';

const creatorOptions = {
  showLogicTab: true,
  isAutoSave: false,
};

export default {
  name: 'ChecklistManage',
  data: () => ({
    formData: null,
  }),
  mounted() {
    this.loadSurveyCreator();
  },
  methods: {
    loadSurveyCreator() {
      const creator = new SurveyCreator(creatorOptions);
      creator.saveSurveyFunc = (saveNo, callback) => {
        this.surveyComplete(creator, saveNo, callback);
      };

      if (+this.$route.query.id) {
        this.loadChecklists(creator);
      }

      creator.render('surveyCreator');
    },
    surveyComplete(creator, saveNo, callback) {
      const pages = creator.JSON.pages;

      pages.forEach((page) => {
        page.visible = true;
        if (page.elements && page.elements.length > 0) {
          page.elements.forEach((e, i) => {
            e.sequence_no = i + 1;
            e.response_type = e.type;
            e.is_required = e.isRequired || null;
            e.response_type_options = JSON.stringify(e.choices) || null;
          });
        }
      });

      let formData = {
        pages,
        title: creator.JSON.title,
        description: creator.JSON.description,
      };

      if (this.formData) {
        delete this.formData.details;
        delete this.formData.pages;
        delete this.formData.title;
        delete this.formData.description;

        formData = { ...formData, ...this.formData };
      }

      let app = null;
      if (+this.$route.query.id) {
        formData.id = +this.$route.query.id;
        app = checklistApi.update(formData);
      } else {
        app = checklistApi.add(formData);
      }

      console.log(formData);

      if (app) {
        app
          .then(({ data }) => {
            this.toastConfig();

            if (!this.$route.query.id) {
              const url = `/pages/dev-tools/checklists/manage?id=${data.id}`;
              this.$router.push(url);
            }
          })
          .catch((err) => {
            this.toastConfig('', 'error');
          })
          .finally(() => {
            //
          });
      }
    },
    loadChecklists(creator = null) {
      checklistApi
        .get(this.$route.query.id)
        .then(({ data }) => {
          this.formData = data;
          var defaultJSON = {
            ...data,
            pages: [],
          };

          if (data.details && data.details.data) {
            const details = data.details.data;

            details.forEach((detail) => {
              detail.elements = [];
              if (detail.checklists && detail.checklists.data) {
                const checklists = detail.checklists.data;

                checklists.forEach((checklist) => {
                  delete checklist.type;
                  checklist.type = checklist.response_type;
                  checklist.name = checklist.title;
                  checklist.title = checklist.title;
                  checklist.description = checklist.description;
                  checklist.visible = true;

                  if (checklist.response_type_options) {
                    checklist.choices = JSON.parse(
                      checklist.response_type_options
                    );
                  }

                  detail.elements.push(checklist);
                });
              }

              delete detail.type;
              detail.visible = true;
              defaultJSON.pages.push(detail);
            });
          }

          creator.text = JSON.stringify(defaultJSON);
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
  },
};
</script>

<style></style>
